import React, { useState } from "react"
import styled, { css } from "styled-components"

import loadable from "@loadable/component"
import { motion } from "framer-motion"
import useResizeHandler from "@components/hooks/useResizeHandler"
import { variants } from "@src/constants"

const FlyingTitle = loadable(() => import("@components/common/FlyingTitle"))
const CopyBlock = loadable(() => import("@components/common/CopyBlock"))
const ImageSlider = loadable(() => import("@components/common/ImageSlider"))
const Wave = loadable(() => import("@components/common/Wave"))
const ContactForm = loadable(() => import("@components/ContactForm"))

const Contacts = ({ title, content }) => {
  const [showForm, setShowForm] = useState(false)
  const { mobileDevice } = useResizeHandler()

  function showFormHandler() {
    return setShowForm(!showForm)
  }

  return (
    <StyledContainer>
      <ContactForm showForm={showForm} showFormHandler={showFormHandler} />
      <StyledWave fullPage child={1}>
        <motion.figure
          key="contacts-wave-1"
          initial="initial"
          animate="animate"
          variants={variants.slideIn}
          transition={{
            delay: 0.1,
            duration: 0.5,
            ease: "easeIn",
          }}
          className="image"
        >
          <img src="/img/background/contacts/Group 1037.png" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={2}>
        <motion.figure
          key="contacts-wave-1"
          initial="initial"
          animate="animate"
          variants={variants.slideIn}
          transition={{
            delay: 0.2,
            duration: 0.5,
            ease: "easeIn",
          }}
          className="image"
        >
          <img src="/img/background/contacts/Group 1044.png" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={3}>
        <motion.figure
          key="contacts-wave-1"
          initial="initial"
          animate="animate"
          variants={variants.slideIn}
          transition={{
            delay: 0.3,
            duration: 0.5,
            ease: "easeIn",
          }}
          className="image"
        >
          <img src="background/contacts/Group 1036.png" alt="" />
        </motion.figure>
      </StyledWave>
      <FlyingTitle>{title}</FlyingTitle>
      <div className="column-container">
        <div className="column column-1">
          <div className="rows">
            {content[0] && content[0].contentType === "gallery" && (
              <div className="row">
                <ImageSlider
                  motionKey="contacts-img-1"
                  initial="initial"
                  animate="animate"
                  variants={
                    mobileDevice ? variants.fade : variants.slideDownFull
                  }
                  transition={{
                    duration: 0.7,
                    delay: 0.1,
                    ease: "easeIn",
                  }}
                  images={content[0].gallery}
                  className="image"
                  maxWidth="460px"
                />
              </div>
            )}
            {content[1] && content[1].contentType === "gallery" && (
              <div className="row">
                <ImageSlider
                  motionKey="contacts-img-2"
                  initial="initial"
                  animate="animate"
                  variants={mobileDevice ? variants.fade : variants.slideUpFull}
                  transition={{
                    duration: 0.7,
                    delay: 0.2,
                    ease: "easeIn",
                  }}
                  images={content[1].gallery}
                  className="image"
                  maxWidth="470px"
                />
              </div>
            )}
          </div>
        </div>

        <div className="column column-2">
          <div className="rows">
            {content[2] && content[2].contentType === "gallery" && (
              <div className="row">
                <ImageSlider
                  motionKey="contacts-img-3"
                  initial="initial"
                  animate="animate"
                  variants={
                    mobileDevice ? variants.fade : variants.slideInReverseFull
                  }
                  transition={{
                    duration: 0.7,
                    delay: 0.1,
                    ease: "easeIn",
                  }}
                  images={content[2].gallery}
                  className="image"
                  maxWidth="615px"
                />
              </div>
            )}
            {content[3] && content[3].contentType === "copy" && (
              <motion.article
                key="contacts-copy-1"
                initial="initial"
                animate="animate"
                variants={mobileDevice ? variants.fade : variants.slideUp}
                transition={{
                  delay: 0.4,
                  duration: 0.5,
                  ease: "easeIn",
                }}
                className="row"
              >
                <CopyBlock copy={content[3].copy} />
                <button
                  className="form-trigger"
                  type="button"
                  onClick={() => showFormHandler()}
                >
                  Get in touch
                </button>
              </motion.article>
            )}
          </div>
        </div>

        {content[1] && content[1].contentType === "gallery" && (
          <div className="mobile-content">
            <div className="img-1">
              <ImageSlider
                motionKey="contacts-img-3"
                initial="initial"
                animate="animate"
                variants={mobileDevice ? variants.fade : variants.slideUpFull}
                transition={{
                  duration: 0.7,
                  delay: 0.2,
                  ease: "easeIn",
                }}
                images={content[1].gallery}
                className="img-1 image"
              />
            </div>
          </div>
        )}
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.section`
  @media (min-width: 992px) {
    height: 100%;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
    padding: 50px;
  }

  @media (max-width: 991px) {
    font-size: 15px;
    margin-bottom: 80px;
    padding: 0 30px;
  }

  b,
  strong {
    color: #135193;
    font-weight: 700;
  }

  .column-container {
    width: 100%;
    position: relative;
    z-index: 9;
  }

  @media (min-width: 992px) {
    .column-container {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      height: 100%;
      ${"" /* margin-left: 250px; */}
      margin-left: 180px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .column-container {
      margin-left: 140px;
    }
  }

  .column-container * {
    box-sizing: border-box;
  }

  @media (min-width: 992px) {
    .column-container .column {
      display: flex;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .column-container .column {
      flex-shrink: 0;
      width: 50%;
    }
  }

  @media (min-width: 992px) {
    .column-container .column .rows {
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
  }

  .column-container .column .rows .row {
    width: 100%;
  }

  .column-container .column .rows .row img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (min-width: 992px) {
    .column-container .column.column-1 .row:nth-child(1) {
      text-align: center;
      transform: translateY(-50px);
    }
  }

  ${"" /* @media (min-width: 992px) {
    .column-container .column.column-1 .row:nth-child(1) img {
      max-height: 336px;
    }
  } */}

  @media (max-width: 991px) {
    .column-container .column.column-1 .row:nth-child(1) img {
      width: 200px;
    }
  }

  @media (min-width: 992px) {
    .column-container .column.column-1 .row:nth-child(2) {
      transform: translateY(50px);
    }
  }

  @media (max-width: 991px) {
    .column-container .column.column-1 .row:nth-child(2) {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .column-container .column.column-1 .row:nth-child(2) img {
      max-height: 260px;
    }
  }

  .column-container .column.column-2 .rows {
    justify-content: flex-end;
  }

  @media (max-width: 991px) {
    .column-container .column.column-2 .rows {
      display: flex;
      flex-direction: column;
    }
  }

  @media (min-width: 992px) {
    .column-container .column.column-2 .rows .row {
      ${"" /* transform: translateX(50px); */}
      max-width: 615px;
    }
  }

  .column-container .column.column-2 .rows .row img {
    width: 100%;
  }

  @media (max-width: 991px) {
    .column-container .column.column-2 .rows .row:nth-child(1) {
      order: 2;
      margin-top: 30px;
      transform: translateX(30px);
    }

    .column-container .column.column-2 .rows .row:nth-child(1) img {
      max-width: 100%;
    }
  }

  @media (min-width: 992px) {
    .column-container .column.column-2 .rows .row:nth-child(2) {
      margin: 15px -50px 100px 15px;
    }
  }

  @media (max-width: 991px) {
    .column-container .column.column-2 .rows .row:nth-child(2) {
      order: 1;
    }
  }

  .column-container .column.column-2 .rows .row:nth-child(2) a {
    text-decoration: none;
    color: #000;
  }

  .column-container .column.column-2 .rows .row:nth-child(2) a:hover {
    text-decoration: underline;
  }

  .mobile-content {
    display: none;
    margin-bottom: 30px;
  }

  @media (max-width: 991px) {
    .mobile-content {
      display: block;
    }
  }

  .mobile-content .img-1 {
    margin-top: 30px;
    transform: translateX(-30px);
  }

  .mobile-content .img-1 img {
    width: 100%;
  }

  .form-trigger,
  .contact-form__submit {
    border: 0;
    background-color: #135193;
    color: #ffffff;
    font-family: inherit;
    padding: 12px 8px 9px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: inherit;
    font-weight: 600;
  }
`

const StyledWave = styled(Wave)`
  ${({ child }) =>
    child === 1 &&
    css`
      left: -20%;
      top: -100%;
      height: 190vh;
    `}
  ${({ child }) =>
    child === 2 &&
    css`
      right: -10%;
      top: -20%;
    `}
  ${({ child }) =>
    child === 3 &&
    css`
      right: -30%;
      top: -10%;
    `}
`

Contacts.displayName = "Contacts"

export default Contacts
