import CopyBlock from "@components/common/CopyBlock"
import React from "react"
import Wave from "@components/common/Wave"
import { motion } from "framer-motion"
import styled from "styled-components"
import { variants } from "@src/constants"

const Home = ({ content }) => (
  <StyledContainer>
    <StyledWave>
      <motion.figure
        key="wave-1"
        initial="initial"
        animate="animate"
        variants={variants.scale}
        transition={{
          delay: 0.5,
          duration: 0.5,
          ease: "easeInOut",
        }}
      >
        <img data-depth="0.5" src="/img/background/wave.svg" alt="" />
      </motion.figure>
    </StyledWave>

    {content[0] && content[0].contentType === "copy" && (
      <article className="text">
        <motion.div
          key="home-text"
          initial="initial"
          animate="animate"
          variants={variants.scale}
          transition={{
            delay: 0.8,
            duration: 0.5,
            ease: "easeInOut",
          }}
        >
          <CopyBlock copy={content[0].copy} />
        </motion.div>
      </article>
    )}
  </StyledContainer>
)

const StyledContainer = styled.section`
  @media (min-width: 992px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
  }

  @media (max-width: 991px) {
    width: 100vw;
    height: calc(100vh - 100px);
    display: block;
    padding: 0 30px 0 30px;
  }

  .text {
    width: 375px;
    max-width: 100%;
    text-align: center;
    letter-spacing: -0.07px;
    color: #000;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      text-align: center;

      :last-child {
        margin-top: 1rem;
      }
    }

    * {
      margin: 0;
    }

    @media (max-width: 991px) {
      max-width: 70%;
    }

    h1 {
      margin-bottom: 20px;
      text-transform: uppercase;
      color: #135193;
      font-size: 1rem;
    }
  }
`

const StyledWave = styled(Wave)`
  padding: 80px;

  @media (max-width: 991px) {
    display: block;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      max-width: 190vw;
    }
  }

  @media (min-width: 992px) {
    ::before,
    ::after {
      content: "";
      border: solid 1px rgba(178, 178, 178, 0.3);
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    ::before {
      width: calc(100% + 10px);
      height: calc(100% + 10px);
    }

    ::after {
      width: 100%;
      height: 100%;
    }
  }
`

Home.displayName = "Home"

export default Home
