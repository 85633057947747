import styled, { css } from "styled-components"

import React from "react"
import loadable from "@loadable/component"
import { motion } from "framer-motion"
import useResizeHandler from "@components/hooks/useResizeHandler"
import { variants } from "@src/constants"

const FlyingTitle = loadable(() => import("@components/common/FlyingTitle"))
const CopyBlock = loadable(() => import("@components/common/CopyBlock"))
const ImageSlider = loadable(() => import("@components/common/ImageSlider"))
const Wave = loadable(() => import("@components/common/Wave"))

const Team = ({ title, content }) => {
  const { mobileDevice } = useResizeHandler()
  return (
    <StyledContainer>
      <StyledWave fullPage child={1}>
        <motion.figure
          key="team-wave-1"
          initial="initial"
          animate="animate"
          variants={variants.scale}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.1,
          }}
          className="image"
        >
          <img src="/img/background/team/Group 1012.png" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={2}>
        <motion.figure
          key="team-wave-2"
          initial="initial"
          animate="animate"
          variants={variants.scale}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.2,
          }}
          className="image"
        >
          <img src="/img/background/team/Group 1036.png" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={3}>
        <motion.figure
          key="team-wave-3"
          initial="initial"
          animate="animate"
          variants={variants.scale}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.3,
          }}
          className="image"
        >
          <img src="/img/background/team/Group 1037.png" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledFlyingTitle>{title}</StyledFlyingTitle>
      <div className="row row-1">
        <article className="text-1">
          <motion.div
            key="team-text-1"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.scale}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              delay: 0.1,
            }}
          >
            {content[0] && content[0].contentType === "copy" && (
              <CopyBlock copy={content[0].copy} />
            )}
          </motion.div>
        </article>

        <div className="right-content">
          <article className="text-2">
            <motion.div
              key="team-text-2"
              initial="initial"
              animate="animate"
              variants={mobileDevice ? variants.fade : variants.slideDown}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
                delay: 0.2,
              }}
            >
              {content[1] && content[1].contentType === "copy" && (
                <CopyBlock copy={content[1].copy} />
              )}
            </motion.div>
          </article>
          {content[2] && content[2].contentType === "gallery" && (
            <ImageSlider
              key="team-image-1"
              initial="initial"
              animate="animate"
              variants={mobileDevice ? variants.fade : variants.slideDownFull}
              transition={{
                duration: 0.7,
                ease: "easeInOut",
                delay: 0.3,
              }}
              className="img-1"
              images={content[2].gallery}
              maxWidth="280px"
            />
          )}
        </div>
      </div>
      <div className="row row-2">
        <article className="text-3">
          <motion.div
            key="team-text-3"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.scale}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              delay: 0.3 + 0.5,
            }}
            data-effect-order="4"
            data-effect="scale"
          >
            {content[3] && content[3].contentType === "copy" && (
              <CopyBlock images={content[3].copy} />
            )}
          </motion.div>
        </article>

        <article className="text-4">
          <motion.div
            key="team-text-4"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.scale}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              delay: 0.1 * 5,
            }}
            data-effect-order="5"
            data-effect="scale"
          >
            {content[3] && content[3].contentType === "copy" && (
              <CopyBlock copy={content[3].copy} />
            )}
            {content[4] && content[4].contentType === "copy" && (
              <CopyBlock copy={content[4].copy} />
            )}
          </motion.div>
        </article>

        {content[4] && content[4].contentType === "gallery" && (
          <ImageSlider
            key="team-image-2"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.slideUpFull}
            transition={{
              duration: 0.7,
              ease: "easeInOut",
              delay: 0.3 + 0.8,
            }}
            className="img-2"
            images={content[4].gallery}
            maxWidth="335px"
          />
        )}
      </div>

      <div className="mobile-content">
        {content[4] && content[4].contentType === "copy" && (
          <article className="text-5">
            <motion.div
              key="team-text-5"
              initial="initial"
              animate="animate"
              variants={mobileDevice ? variants.fade : variants.scale}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
                delay: 0.1 * 6,
              }}
              data-effect-order="6"
              data-effect="scale"
            >
              <CopyBlock copy={content[4].copy} />
            </motion.div>
          </article>
        )}
        {content[2] && content[2].contentType === "gallery" && (
          <ImageSlider
            key="team-image-3"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.slideDownFull}
            transition={{
              duration: 0.7,
              ease: "easeInOut",
              delay: 0.1 * 6,
            }}
            className="img-3"
            images={content[2].gallery}
          />
        )}

        <article className="text-6">
          <motion.div
            key="team-text-6"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.slideDown}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              delay: 0.1 * 8,
            }}
            data-effect-order="8"
            data-effect="slide-down"
          >
            {content[1] && content[1].contentType === "copy" && (
              <CopyBlock copy={content[1].copy} />
            )}
          </motion.div>
        </article>
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.section`
  width: 100%;

  @media (min-width: 992px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    padding: 50px;

    h2,
    h3 {
      font-size: 15px;
    }
  }
  @media (max-width: 991px) {
    font-size: 15px;
    padding: 0 !important;
    margin-bottom: 80px;

    h2,
    h3 {
      font-size: 15px;
    }
  }

  p {
    margin: 0;
  }
  ${"" /* @media (max-width: 991px) {
    .flying-title {
      padding: 0 30px;
    }
  } */}

  .row {
    position: relative;
    height: 50%;
    width: 100%;
  }
  @media (min-width: 992px) {
    .row.row-1 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 {
      padding: 0 30px;
    }
  }
  .row.row-1 .text-1 {
    color: #fff;
    color: #000;
  }
  @media (min-width: 992px) {
    .row.row-1 .text-1 {
      padding: 50px;
      align-self: flex-end;
      transform: translate(-50px, 35px);
      width: 335px;
      max-width: 33.3333333333%;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 .text-1 {
      padding: 40px 40px 60px 40px;
      margin-top: 10px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-1 .text-1 {
      padding: 25px;
      flex-shrink: 0;
      transform: translate(-50px, -35px);
    }
  }

  @media (min-width: 1199px) {
    .row.row-1 .text-1 {
      align-self: flex-start;
    }
  }

  .row.row-1 .right-content {
    width: 900px;
    max-width: 66.6666666667%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    /* padding-right: 200px; */
    padding-right: 150px;
  }
  @media (max-width: 991px) {
    .row.row-1 .right-content {
      display: none;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-1 .right-content {
      margin-top: 30px;
      /* padding-right: 75px; */
      padding-right: 50px;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      max-width: none;
    }
  }
  .row.row-1 .right-content .text-2 {
    width: 500px;
    text-align: right;
    margin-right: 20px;
  }

  @media (min-width: 1280px) {
    .row.row-1 .right-content .text-2 {
      max-width: 50%;
      margin-right: 35px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-1 .right-content .text-2 {
      max-width: none;
      text-align: left;
      width: 100%;
    }
  }
  .row.row-1 .right-content .img-1 img {
    max-width: 100%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-1 .right-content .img-1 {
      /* width: 250px; */
      width: 180px;
      flex-shrink: 0;
    }
    .row.row-1 .right-content .img-1 img {
      width: 100%;
    }
  }
  .row.row-2 {
    display: flex;
  }
  @media (min-width: 992px) {
    .row.row-2 {
      padding: 0 50px 0 120px;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
  @media (max-width: 991px) {
    .row.row-2 {
      padding: 0 30px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-2 {
      justify-content: flex-start;
    }
  }
  @media (min-width: 992px) {
    .row.row-2 .text-3 {
      /* width: 250px; */
      width: 360px;
      max-width: 33.3333333333%;
      padding: 0 50px 20px 0;
      display: none;
    }
  }
  @media (max-width: 991px) {
    .row.row-2 .text-3 {
      width: 50%;
      order: 2;
      margin-top: 30px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-2 .text-3 {
      padding: 0 50px 0 0;
    }
  }
  @media (min-width: 992px) {
    .row.row-2 .text-4 {
      /* width: 360px;
      max-width: 33.3333333333%;
      padding: 0 70px 20px 0; */
      width: 420px;
      max-width: 60%;
      padding: 0 70px 25px 0;
    }
  }
  @media (max-width: 991px) {
    .row.row-2 .text-4 {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .row.row-2 .img-2 {
      width: 440px;
      max-width: 33.3333333333%;
    }
  }
  @media (max-width: 991px) {
    .row.row-2 .img-2 {
      order: 1;
      margin-top: 30px;
      padding-right: 15px;
      width: 50%;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-2 .img-2 {
      width: 200px;
    }
  }
  .row.row-2 .img-2 img {
    max-width: 100%;
  }
  @media (min-width: 992px) {
    .row.row-2 .img-2 img {
      max-height: 50vh;
      transform: translateY(57px);
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-2 .img-2 img {
      width: 100%;
    }
  }
  .mobile-content {
    display: none;
  }
  @media (max-width: 991px) {
    .mobile-content {
      display: block;
    }
  }
  .mobile-content .text-5 {
    margin-top: 15px;
    padding: 0 30px;
  }
  ${"" /* @media (max-width: 991px) {
  } */}
  .mobile-content .img-3 {
    width: 100%;
    overflow: hidden;
    margin: 20px 0;
  }
  .mobile-content .img-3 img {
    max-width: 100%;
  }
  .mobile-content .text-6 {
    text-align: right;
    margin-bottom: 60px;
    padding: 0 30px;
  }
`

const StyledFlyingTitle = styled(FlyingTitle)`
  @media (max-width: 991px) {
    padding: 0 30px;
  }
`

const StyledWave = styled(Wave)`
  ${({ child }) =>
    child === 1 &&
    css`
      right: -30%;
      top: -70%;
      height: 200vh;
    `}
  ${({ child }) =>
    child === 2 &&
    css`
      top: -27%;
      left: 10%;
    `}
  ${({ child }) =>
    child === 3 &&
    css`
      top: -20%;
      left: 0;
    `}
`

Team.displayName = "Team"

export default Team
