import React from "react"
import styled from "styled-components"

const CopyBlock = ({ copy, align }) => (
  <StyledCopyBlock align={align} dangerouslySetInnerHTML={{ __html: copy }} />
)

const StyledCopyBlock = styled.div`
  h2 {
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: #135193;
  }

  h3 {
    font-weight: 400;
  }

  p {
    text-align: ${({ align }) => (align ? align : "justify")};
  }
`

CopyBlock.displayName = "CopyBlock"

export default CopyBlock
