import styled, { css } from "styled-components"

import React from "react"
import loadable from "@loadable/component"
import { motion } from "framer-motion"
import useResizeHandler from "@components/hooks/useResizeHandler"
import { variants } from "@src/constants"

const FlyingTitle = loadable(() => import("@components/common/FlyingTitle"))
const CopyBlock = loadable(() => import("@components/common/CopyBlock"))
const ImageSlider = loadable(() => import("@components/common/ImageSlider"))
const Wave = loadable(() => import("@components/common/Wave"))

const Innovation = ({ title, content }) => {
  const { mobileDevice } = useResizeHandler()

  return (
    <StyledContainer>
      <StyledWave fullPage child={1}>
        <motion.figure
          key="innovation-wave-1"
          initial="initial"
          animate="animate"
          variants={variants.scale}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.1,
          }}
          className="image"
        >
          <img src="/img/background/innovation/Group 950.svg" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={2}>
        <motion.figure
          key="innovation-wave-2"
          initial="initial"
          animate="animate"
          variants={variants.scale}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.2,
          }}
          className="image"
        >
          <img src="/img/background/innovation/Group 959.svg" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={3}>
        <motion.figure
          key="innovation-wave-3"
          initial="initial"
          animate="animate"
          variants={variants.scale}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.3,
          }}
          className="image"
        >
          <img src="/img/background/innovation/Group 1011.svg" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={4}>
        <motion.figure
          key="innovation-wave-3"
          initial="initial"
          animate="animate"
          variants={variants.scale}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.4,
          }}
          className="image"
        >
          <img src="/img/background/innovation/Group 1011.svg" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledFlyingTitle>{title}</StyledFlyingTitle>
      <div className="row row-1">
        {content[0] && content[0].contentType === "gallery" && (
          <ImageSlider
            key="innovation-image-1"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.slideInFull}
            transition={{
              duration: 0.7,
              ease: "easeInOut",
              delay: 0.5 + 0.1,
            }}
            className="image-1"
            images={content[0].gallery}
            maxWidth="400px"
          />
        )}

        {content[1] && content[1].contentType === "copy" && (
          <article className="text-1">
            <motion.div
              key="innovation-text-1"
              initial="initial"
              animate="animate"
              variants={mobileDevice ? variants.fade : variants.scale}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
                delay: 0.5 + 0.1 * 2,
              }}
            >
              <CopyBlock copy={content[1].copy} />
            </motion.div>
          </article>
        )}

        {content[2] && content[2].contentType === "gallery" && (
          <ImageSlider
            key="innovation-image-2"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.slideDownFull}
            transition={{
              duration: 0.7,
              ease: "easeInOut",
              delay: 0.5 + 0.1 * 3,
            }}
            className="image-2"
            images={content[2].gallery}
            maxWidth="350px"
          />
        )}
      </div>

      <div className="row row-2">
        {content[3] && content[3].contentType === "copy" && (
          <article className="text-2">
            <motion.div
              key="innovation-text-2"
              initial="initial"
              animate="animate"
              variants={mobileDevice ? variants.fade : variants.scale}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
                delay: 0.5 + 0.1 * 4,
              }}
              data-effect="scale"
              data-effect-order="4"
            >
              <CopyBlock copy={content[3].copy} />
            </motion.div>
          </article>
        )}

        {content[4] && content[4].contentType === "gallery" && (
          <ImageSlider
            key="innovation-image-3"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.slideUpFull}
            transition={{
              duration: 0.7,
              ease: "easeInOut",
              delay: 0.5 + 0.1 * 5,
            }}
            className="image-3"
            images={content[4].gallery}
            maxWidth="455px"
          />
        )}

        {content[5] && content[5].contentType === "copy" && (
          <article className="text-3">
            <motion.div
              key="innovation-text-3"
              initial="initial"
              animate="animate"
              variants={mobileDevice ? variants.fade : variants.scale}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
                delay: 0.5 + 0.1 * 6,
              }}
              data-effect="scale"
              data-effect-order="6"
            >
              <CopyBlock copy={content[5].copy} />
            </motion.div>
          </article>
        )}
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.section`
  width: 100%;

  @media (min-width: 992px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 50px 0;
  }

  @media (max-width: 991px) {
    font-size: 15px;
    padding: 0 !important;
    margin-bottom: 60px;
  }

  p {
    margin: 0;
  }

  ${"" /* @media (max-width: 991px) {
    .flying-title {
      padding: 0 30px;
    }
  } */}

  .row {
    position: relative;
  }
  @media (min-width: 992px) {
    .row {
      display: flex;
    }
  }
  .row.row-1 {
    justify-content: flex-start;
  }
  @media (min-width: 992px) {
    .row.row-1 {
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 {
      padding: 0 30px;
    }
  }
  @media (min-width: 992px) {
    .row.row-1 .image-1 {
      margin: 110px 0 0 0;
    }
  }
  @media (min-width: 1200px) {
    .row.row-1 .image-1 img {
      max-height: calc(100vh - 424px);
      min-height: 230px;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 .image-1 img {
      width: 230px;
      max-width: 100%;
    }
  }
  ${"" /* @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-1 .image-1 img {
      height: 250px;
    }
  } */}
  @media (min-width: 992px) {
    .row.row-1 .text-1 {
      /* margin: 130px 0 0 60px; */
      margin: 110px 0 0 30px;
      max-width: 400px;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 .text-1 {
      margin: 30px 0;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-1 .text-1 {
      max-width: 250px;
    }
  }
  @media (min-width: 992px) {
    .row.row-1 .image-2 {
      position: absolute;
      right: 20px;
      top: -50px;
    }
  }
  @media (min-width: 1200px) {
    .row.row-1 .image-2 img {
      max-height: calc(100vh - 424px);
      min-height: 230px;
    }
  }
  @media (min-width: 1400px) {
    .row.row-1 .image-2 {
      right: 160px;
    }
  }
  ${"" /* @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-1 .image-2 img {
      height: 250px;
    }
  } */}
  @media (min-width: 992px) {
    .row.row-2 {
      margin: 0 0 0 270px;
      height: 250px;
      flex-shrink: 0;
      justify-content: flex-end;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-2 {
      margin: 0 170px;
    }
  }
  @media (min-width: 1200px) {
    .row.row-2 .text-2 {
      /* max-width: 280px; */
      max-width: 300px;
      /* margin: 0 40px 0 0; */
      margin: 0 30px 0 0;
      text-align: right;
    }
  }
  @media (max-width: 991px) {
    .row.row-2 .text-2 {
      margin: 30px 0 40px 0;
      padding: 0 30px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-2 .text-2 {
      margin: 0 40px 0 0;
    }
    .row.row-2 .text-2 * {
      word-break: break-all;
    }
  }
  @media (min-width: 1200px) {
    .row.row-2 .image-3 {
      height: calc(100% + 50px);
    }
  }
  @media (max-width: 991px) {
    .row.row-2 .image-3 {
      overflow: hidden;
    }
    .row.row-2 .image-3 img {
      max-width: 100%;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-2 .image-3 {
      width: 300px;
      flex-shrink: 0;
    }
    .row.row-2 .image-3 img {
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    .row.row-2 .text-3 {
      /* max-width: 280px; */
      max-width: 300px;
      /* margin: 40px 150px 0 40px; */
      margin: 0px 150px 0 30px;
      min-width: 180px;
    }
  }
  @media (max-width: 991px) {
    .row.row-2 .text-3 {
      margin: 30px 0 40px 0;
      padding: 0 30px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-2 .text-3 {
      margin: 0 0 0 40px;
    }
  }

  @media (min-width: 992px) {
    article,
    article h2,
    article h3 {
      font-size: 15px;
    }
  }
`

const StyledFlyingTitle = styled(FlyingTitle)`
  @media (max-width: 991px) {
    padding: 0 30px;
  }
`

const StyledWave = styled(Wave)`
  ${({ child }) =>
    child === 1 &&
    css`
      left: -7%;
      top: -16%;
    `}
  ${({ child }) =>
    child === 2 &&
    css`
      top: -55%;
      left: 0%;
    `}
  ${({ child }) =>
    child === 3 &&
    css`
      right: -40%;
      top: -70%;
    `}
`

Innovation.displayName = "Innovation"

export default Innovation
