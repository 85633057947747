import styled, { css } from "styled-components"

import React from "react"
import loadable from "@loadable/component"
import { motion } from "framer-motion"
import useResizeHandler from "@components/hooks/useResizeHandler"
import { variants } from "@src/constants"

const FlyingTitle = loadable(() => import("@components/common/FlyingTitle"))
const CopyBlock = loadable(() => import("@components/common/CopyBlock"))
const ImageSlider = loadable(() => import("@components/common/ImageSlider"))
const Wave = loadable(() => import("@components/common/Wave"))

const Vibes = ({ title, content }) => {
  const { mobileDevice } = useResizeHandler()
  return (
    <StyledContainer>
      <StyledWave fullPage child={1}>
        <motion.figure
          key="vibes-wave-1"
          initial="initial"
          animate="animate"
          variants={variants.slideInReverse}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.2,
          }}
          className="image"
        >
          <img src="/img/background/vibes/Group 949.svg" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={2}>
        <motion.figure
          key="vibes-wave-2"
          initial="initial"
          animate="animate"
          variants={variants.slideIn}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.1,
          }}
          className="image"
        >
          <img src="/img/background/vibes/Group 950.svg" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={3}>
        <motion.figure
          key="vibes-wave-3"
          initial="initial"
          animate="animate"
          variants={variants.slideInReverse}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.2,
          }}
          className="image"
        >
          <img src="/img/background/vibes/Group 1011.svg" alt="" />
        </motion.figure>
      </StyledWave>
      <FlyingTitle>{title}</FlyingTitle>
      <div className="row row-1">
        {content[0] && content[0].contentType === "gallery" && (
          <ImageSlider
            key="team-image-1"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.slideIn}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              delay: 0.1,
            }}
            className="image-1"
            maxWidth="351px"
            images={content[0].gallery}
          />
        )}
        <article className="text-1">
          {content[1] && content[1].contentType === "copy" && (
            <motion.div
              key="vibes-text-1"
              initial="initial"
              animate="animate"
              variants={mobileDevice ? variants.fade : variants.scale}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
                delay: 0.5,
              }}
            >
              <CopyBlock copy={content[1].copy} />
            </motion.div>
          )}
        </article>
      </div>

      <div className="row row-2">
        {content[2] && content[2].contentType === "copy" && (
          <motion.article
            key="team-text-3"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.scale}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              delay: 0.3,
            }}
            className="text-3"
          >
            <CopyBlock copy={content[2].copy} />
          </motion.article>
        )}

        {content[3] && content[3].contentType === "gallery" && (
          <ImageSlider
            key="team-img-2"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.slideUpFull}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
              delay: 0.2,
            }}
            className="image-2"
            images={content[3].gallery}
            maxWidth="500px"
          />
        )}
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.section`
  @media (min-width: 992px) {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 50px 0 50px;
  }

  @media (max-width: 991px) {
    font-size: 15px;
    padding: 0 30px;
  }

  p {
    margin: 0;
  }

  h2,
  h3 {
    color: #135193;
    font-weight: 700;
    text-transform: uppercase;
  }

  .row {
    position: relative;
    display: flex;
  }
  @media (min-width: 992px) {
    .row.row-1 {
      justify-content: flex-start;
      margin: 90px 0 0 160px;
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 {
      margin-top: 30px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-1 {
      margin: 90px 0 0 0;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 .image-1 {
      width: 150px;
      flex-shrink: 0;
    }
  }
  @media (min-width: 992px) {
    .row.row-1 .image-1 img {
      max-height: calc(100vh - 100px - 90px - 234px);
      min-height: 230px;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 .image-1 img {
      max-width: 100%;
    }
  }
  @media (min-width: 992px) {
    .row.row-1 .text-1 {
      /* margin: 60px 0 0 35px; */
      margin: 0px 0 0 35px;
      max-width: 350px;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 .text-1 {
      padding-left: 15px;
    }
  }
  @media (min-width: 992px) {
    .row.row-2 {
      justify-content: flex-end;
      margin: 0 180px 0 210px;
      height: 284px;
      flex-shrink: 0;
    }
  }
  @media (max-width: 991px) {
    .row.row-2 {
      flex-direction: column;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-2 {
      margin: 0 125px 0 210px;
    }
  }
  @media (min-width: 992px) {
    .row.row-2 .text-3 {
      max-width: 400px;
      /* margin: 35px 44px 0 0; */
      margin: 0 44px 0 0;
    }
  }
  @media (max-width: 991px) {
    .row.row-2 .text-3 {
      order: 2;
      margin: 30px 0 80px 0;
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }
  }
  @media (min-width: 992px) {
    .row.row-2 .image-2 {
      ${"" /* max-width: 50%; */}
      height: 100%;
    }
  }
  @media (max-width: 991px) {
    .row.row-2 .image-2 {
      order: 1;
      margin-top: 30px;
    }
    .row.row-2 .image-2 img {
      max-width: 100%;
    }
  }

  @media (min-width: 992px) {
    article,
    article h2,
    article h3 {
      font-size: 15px;
    }
  }
`

const StyledWave = styled(Wave)`  
  ${({ child }) =>
    child === 1 &&
    css`
      right: 10%;
      top: -20%;
    `}
  ${({ child }) =>
    child === 2 &&
    css`
      left: 18%;
      top: -30%;
    `}
  ${({ child }) =>
    child === 3 &&
    css`
      right: -50%;
      top: -50%;
    `}
`

Vibes.displayName = "Vibes"

export default Vibes
