import React from "react"
import loadable from "@loadable/component"
import styled from "styled-components"

const CopyBlock = loadable(() => import("@components/common/CopyBlock"))

const PrivacyPolicy = ({ content }) => {
  return (
    <StyledContainer>
      <div className="column-container">
        <div className="column column-1">
          <div className="rows">
            <div className="row">
              {content[0] && content[0].contentType === "copy" && (
                <CopyBlock copy={content[0].copy} align="left" />
              )}
            </div>
          </div>
        </div>
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.section`
  overflow: auto;
  padding: 0 30px;

  @media (min-width: 992px) {
    padding: 110px 15% 0;
  }

  h1,
  a {
    color: #135193;
  }

  h1 {
    font-size: 40px;
    color: #135193;
  }
`

PrivacyPolicy.displayName = "PrivacyPolicy"

export default PrivacyPolicy
