import styled, { css } from "styled-components"

import React from "react"
import loadable from "@loadable/component"
import { motion } from "framer-motion"
import useResizeHandler from "@components/hooks/useResizeHandler"
import { variants } from "@src/constants"

const FlyingTitle = loadable(() => import("@components/common/FlyingTitle"))
const CopyBlock = loadable(() => import("@components/common/CopyBlock"))
const ImageSlider = loadable(() => import("@components/common/ImageSlider"))
const Wave = loadable(() => import("@components/common/Wave"))

const Approach = ({ title, content }) => {
  const { mobileDevice } = useResizeHandler()

  console.log({ content })

  return (
    <StyledContainer>
      <StyledWave fullPage child={1}>
        <motion.figure
          key="approach-wave-1"
          initial="initial"
          animate="animate"
          variants={variants.slideIn}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.1,
          }}
          className="image"
        >
          <img src="/img/background/approach/Group 950.png" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={2}>
        <motion.figure
          key="approach-wave-2"
          initial="initial"
          animate="animate"
          variants={variants.slideIn}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.2,
          }}
          className="image"
        >
          <img src="/img/background/approach/Group 1011.png" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={3}>
        <motion.figure
          key="approach-wave-3"
          initial="initial"
          animate="animate"
          variants={variants.slideDown}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.3,
          }}
          className="image"
        >
          <img src="/img/background/approach/Group 1017.png" alt="" />
        </motion.figure>
      </StyledWave>
      <FlyingTitle>{title}</FlyingTitle>
      <div className="column-container">
        <motion.div
          key="approach-column-1"
          initial="initial"
          animate="animate"
          variants={mobileDevice ? variants.fade : variants.scale}
          transition={{
            delay: 0.1,
            duration: 0.5,
            ease: "easeInOut",
          }}
          className="column column-1"
        >
          <div className="rows">
            {content[0] && content[0].contentType === "copy" && (
              <div className="row">
                <CopyBlock copy={content[0].copy} />
              </div>
            )}
            {content[1] && content[1].contentType === "copy" && (
              <div className="row">
                <CopyBlock copy={content[1].copy} />
              </div>
            )}
          </div>
        </motion.div>

        <motion.div
          key="approach-column-2"
          initial="initial"
          animate="animate"
          variants={mobileDevice ? variants.fade : variants.scale}
          transition={{
            delay: 0.3,
            duration: 0.5,
            ease: "easeInOut",
          }}
          className="column column-2"
        >
          {content[1] && content[1].contentType === "gallery" && (
            <ImageSlider images={content[1].gallery} maxWidth="350px" />
          )}

          {content[2] && content[2].contentType === "gallery" && (
            <ImageSlider images={content[2].gallery} maxWidth="350px" />
          )}

          <div className="text">
            {content[0] && content[0].contentType === "copy" && (
              <div className="row">
                <CopyBlock copy={content[0].copy} />
              </div>
            )}
          </div>
        </motion.div>

        <motion.div
          key="approach-column-3"
          initial="initial"
          animate="animate"
          variants={mobileDevice ? variants.fade : variants.scale}
          transition={{
            delay: 0.5,
            duration: 0.5,
            ease: "easeInOut",
          }}
          className="column column-3"
        >
          <div className="rows">
            {content[2] && content[2].contentType === "copy" && (
              <div className="row">
                <CopyBlock copy={content[2].copy} />
              </div>
            )}
            {content[3] && content[3].contentType === "copy" && (
              <div className="row">
                <CopyBlock copy={content[3].copy} />
              </div>
            )}
            {content[4] && content[4].contentType === "copy" && (
              <div className="row">
                <CopyBlock copy={content[4].copy} />
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.section`
  max-width: 100vw;
  width: 100%;

  @media (min-width: 992px) {
    height: 100vh;
    font-size: 15px;
    display: flex;
    align-items: center;

    h2,
    h3 {
      font-size: 15px;
    }
  }
  @media (max-width: 991px) {
    font-size: 15px;
    margin-bottom: 40px;
    padding: 0 30px;

    h2,
    h3 {
      font-size: 15px;
    }
  }

  h2,
  h3 {
    text-transform: uppercase;
    color: #135193;
  }

  p {
    margin: 0;
  }

  .column-container {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
  }

  .column-container * {
    box-sizing: border-box;
  }

  @media (min-width: 992px) {
    .column-container {
      padding: 0 50px;
    }
  }

  @media (max-width: 991px) {
    .column-container {
      flex-direction: column;
    }
  }

  .column-container .column {
    display: flex;
  }

  @media (max-width: 991px) {
    .column-container .column {
      width: 100%;
    }
  }

  .column-container .column .rows {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
  }

  .column-container .column .rows .row {
    width: 100%;
  }

  .column-container .column.column-1 {
    width: 430px;
    max-width: 100%;
  }

  @media (max-width: 991px) {
    .column-container .column.column-1 {
      order: 2;
    }
  }

  @media (min-width: 992px) {
    .column-container .column.column-1 .rows {
      align-items: flex-end;
      text-align: right;
    }
  }

  .column-container .column.column-1 .rows .row {
    max-width: 100%;
  }

  .column-container .column.column-1 .rows .row:nth-child(1) {
    width: 100%;
  }

  @media (min-width: 992px) {
    .column-container .column.column-1 .rows .row:nth-child(1) {
      /* padding: 20px 100px 0 0; */
      padding: 0px 25px 0 0;
    }
  }

  @media (max-width: 991px) {
    .column-container .column.column-1 .rows .row:nth-child(1) {
      display: none;
    }
  }

  .column-container .column.column-1 .rows .row:nth-child(2) {
    width: 380px;
  }

  @media (min-width: 992px) {
    .column-container .column.column-1 .rows .row:nth-child(2) {
      /* padding: 0 50px 50px 0; */
      padding: 0 25px 0px 0;
    }
  }

  @media (max-width: 991px) {
    .column-container .column.column-1 .rows .row:nth-child(2) {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 992px) {
    .column-container .column.column-2 {
      text-align: center;
      width: 350px;
      max-width: 100%;
    }
  }

  @media (max-width: 991px) {
    .column-container .column.column-2 {
      order: 1;
      margin-bottom: 20px;
      margin-top: 10px;
      display: flex;
    }
  }

  @media (max-width: 991px) {
    .column-container .column.column-2 figure {
      max-width: 50%;
      padding-right: 15px;
    }
  }

  .column-container .column.column-2 figure img {
    max-width: 100%;
  }

  .column-container .column.column-2 .text {
    display: none;
  }

  @media (max-width: 991px) {
    .column-container .column.column-2 .text {
      display: block;
      width: 50%;
    }
  }

  .column-container .column.column-3 {
    width: 420px;
    max-width: 100%;
  }

  @media (max-width: 991px) {
    .column-container .column.column-3 {
      order: 3;
      margin-bottom: 20px;
    }
  }

  .column-container .column.column-3 .rows .row {
    max-width: 100%;
  }

  .column-container .column.column-3 .rows .row:nth-child(1) {
    width: 100%;
  }

  @media (min-width: 992px) {
    .column-container .column.column-3 .rows .row:nth-child(1) {
      /* padding: 70px 0 0 50px; */
      padding: 0 0 0 25px;
    }
  }

  @media (max-width: 991px) {
    .column-container .column.column-3 .rows .row:nth-child(1) {
      margin-bottom: 20px;
    }
  }

  .column-container .column.column-3 .rows .row:nth-child(2) {
    align-self: center;
  }

  @media (min-width: 992px) {
    .column-container .column.column-3 .rows .row:nth-child(2) {
      /* width: 250px;
      padding-bottom: 20px; */
      width: 100%;
      padding-bottom: 0;
      padding-left: 25px;
      align-self: flex-start;
    }
  }
`

const StyledWave = styled(Wave)`
  ${({ child }) =>
    child === 1 &&
    css`
      left: -10%;
    `}
  ${({ child }) =>
    child === 2 &&
    css`
      right: 0;
    `}
  ${({ child }) =>
    child === 3 &&
    css`
      left: -30%;
      top: 0;
    `}
`

Approach.displayName = "Approach"

export default Approach
