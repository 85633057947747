import styled, { css } from "styled-components"

import React from "react"
import loadable from "@loadable/component"
import { motion } from "framer-motion"
import useResizeHandler from "@components/hooks/useResizeHandler"
import { variants } from "@src/constants"

const FlyingTitle = loadable(() => import("@components/common/FlyingTitle"))
const CopyBlock = loadable(() => import("@components/common/CopyBlock"))
const ImageSlider = loadable(() => import("@components/common/ImageSlider"))
const Wave = loadable(() => import("@components/common/Wave"))

const Expertise = ({ title, content }) => {
  const { mobileDevice } = useResizeHandler()
  return (
    <StyledContainer>
      <StyledWave fullPage child={1}>
        <motion.figure
          key="expertise-wave-1"
          intial="initial"
          animate="animate"
          variants={variants.scale}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.1,
          }}
          className="image"
        >
          <img src="/img/background/expertise/Group 1027.png" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={2}>
        <motion.figure
          key="expertise-wave-2"
          intial="initial"
          animate="animate"
          variants={variants.scale}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.2,
          }}
          className="image"
        >
          <img src="/img/background/expertise/Group 1028.png" alt="" />
        </motion.figure>
      </StyledWave>
      <StyledWave fullPage child={3}>
        <motion.figure
          key="expertise-wave-3"
          intial="initial"
          animate="animate"
          variants={variants.scale}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: 0.3,
          }}
          className="image"
        >
          <img src="/img/background/expertise/Group 1029.png" alt="" />
        </motion.figure>
      </StyledWave>
      <FlyingTitle>{title}</FlyingTitle>
      <div className="row row-1">
        {content[0] && content[0].contentType === "copy" && (
          <article className="text-1">
            <motion.div
              key="expertise-text-1"
              initial="initial"
              animate="animate"
              variants={mobileDevice ? variants.fade : variants.scale}
              transition={{
                delay: 0.1 * 1,
                duration: 0.5,
                ease: "easeInOut",
              }}
            >
              <CopyBlock copy={content[0].copy} />
            </motion.div>
          </article>
        )}

        {content[3] && content[3].contentType === "gallery" && (
          <ImageSlider
            motionKey="expertise-image-1"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.scale}
            transition={{
              delay: 0.1,
              duration: 0.7,
              ease: "easeInOut",
            }}
            images={content[3].gallery}
            className="img-2"
            maxWidth="380px"
          />
        )}

        {content[1] && content[1].contentType === "copy" && (
          <article className="text-2">
            <motion.div
              key="expertise-text-2"
              initial="initial"
              animate="animate"
              variants={mobileDevice ? variants.fade : variants.scale}
              transition={{
                delay: 0.2,
                duration: 0.5,
                ease: "easeInOut",
              }}
              data-effect-order="2"
              data-effect="scale"
            >
              <CopyBlock copy={content[1].copy} />
            </motion.div>
          </article>
        )}

        {content[2] && content[2].contentType === "gallery" && (
          <ImageSlider
            motionKey="expertise-image-2"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.slideDownFull}
            transition={{
              delay: 0.3,
              duration: 0.7,
              ease: "easeInOut",
            }}
            images={content[2].gallery}
            className="img-1"
            maxWidth="380px"
          />
        )}
      </div>

      {content[3] && content[3].contentType === "gallery" && (
        <div className="row row-2">
          <ImageSlider
            motionKey="expertise-image-3"
            initial="initial"
            animate="animate"
            variants={mobileDevice ? variants.fade : variants.slideInFull}
            transition={{
              delay: 0.4,
              duration: 0.7,
              ease: "easeInOut",
            }}
            images={content[3].gallery}
            className="img-2"
            maxWidth="380px"
          />
        </div>
      )}

      <div className="row row-3">
        {content[4] && content[4].contentType === "copy" && (
          <article className="text-3">
            <motion.div
              key="expertise-text-3"
              initial="initial"
              animate="animate"
              variants={mobileDevice ? variants.fade : variants.scale}
              transition={{
                delay: 0.5,
                duration: 0.5,
                ease: "easeInOut",
              }}
            >
              <CopyBlock copy={content[4].copy} />
            </motion.div>
          </article>
        )}

        {content[5] && content[5].contentType === "copy" && (
          <article className="text-4">
            <motion.div
              key="expertise-text-4"
              initial="initial"
              animate="animate"
              variants={mobileDevice ? variants.fade : variants.scale}
              transition={{
                delay: 0.6,
                duration: 0.5,
                ease: "easeInOut",
              }}
            >
              <CopyBlock copy={content[5].copy} />
            </motion.div>
          </article>
        )}
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.section`
  width: 100%;

  @media (min-width: 992px) {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    height: 100%;
    padding: 50px 0;

    h2,
    h3 {
      font-size: 15px;
    }
  }

  @media (max-width: 991px) {
    font-size: 15px;
    margin-bottom: 80px;
    padding: 0 30px;

    h2,
    h3 {
      font-size: 15px;
    }
  }

  h3 {
    margin: 0;
  }

  b,
  strong {
    color: #135193;
    font-weight: 700;
  }

  .row {
    position: relative;
    height: 33.33333%;
    width: 100%;
  }
  @media (min-width: 992px) {
    .row {
      padding: 0 50px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row {
      padding: 0 100px 0 50px;
    }
  }
  .row.row-1 {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 991px) {
    .row.row-1 {
      flex-direction: column;
    }
  }
  .row.row-1 .text-1 {
    width: 400px;
  }
  @media (min-width: 992px) {
    .row.row-1 .text-1 {
      margin-top: 70px;
      max-width: 33.33333%;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 .text-1 {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-1 .text-1 {
      margin-top: 60px;
      margin-right: 20px;
    }
  }
  .row.row-1 .text-2 {
    width: 350px;
  }
  @media (min-width: 992px) {
    .row.row-1 .text-2 {
      margin-top: 70px;
      max-width: 33.33333%;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 .text-2 {
      width: 100%;
      margin: 10px 0 15px 0;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-1 .text-2 {
      margin-top: 60px;
    }
  }
  .row.row-1 .img-1 {
    width: 380px;
  }
  @media (min-width: 992px) {
    .row.row-1 .img-1 {
      max-width: 33.33333%;
      margin-top: -50px;
    }
  }
  @media (max-width: 991px) {
    .row.row-1 .img-1 {
      width: 100%;
      text-align: center;
    }
    .row.row-1 .img-1 img {
      max-width: 100%;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-1 .img-1 {
      width: 300px;
    }
    .row.row-1 .img-1 img {
      width: 100%;
    }
  }
  .row.row-1 .img-2 {
    display: none;
  }
  @media (max-width: 991px) {
    .row.row-1 .img-2 {
      display: block;
    }
  }
  .row.row-1 .img-2 img {
    max-width: 100%;
  }
  .row.row-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media (min-width: 992px) {
    .row.row-2 {
      transform: translateY(50px);
    }
  }
  .row.row-2 .img-2 {
    margin-left: -50px;
    width: 380px;
    max-width: 33.33333%;
  }
  @media (max-height: 730px) {
    .row.row-2 .img-2 {
      width: 310px;
    }
    .row.row-2 .img-2 img {
      max-width: 100%;
    }
  }
  @media (max-width: 991px) {
    .row.row-2 .img-2 {
      display: none;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .row.row-2 .img-2 {
      width: 300px;
    }
    .row.row-2 .img-2 img {
      width: 100%;
    }
  }
  @media (min-width: 992px) {
    .row.row-3 {
      display: flex;
      justify-content: flex-end;
      padding-top: 50px;
    }
  }
  @media (min-width: 992px) {
    .row.row-3 .text-3 {
      width: 500px;
      max-width: 33.33333%;
      margin-right: 60px;
    }
  }
  @media (max-width: 991px) {
    .row.row-3 .text-3 {
      margin-top: 25px;
    }
  }
  @media (min-width: 992px) {
    .row.row-3 .text-4 {
      width: 360px;
      max-width: 33.33333%;
    }
  }
  @media (max-width: 991px) {
    .row.row-3 .text-4 {
      margin-top: 60px;
    }
  }
`

const StyledWave = styled(Wave)`
  ${({ child }) =>
    child === 1 &&
    css`
      right: -20%;
      top: 0%;
      height: 110vh;
    `}
  ${({ child }) =>
    child === 2 &&
    css`
      left: 0;
      top: -25%;
      height: 200vh;
    `}
  ${({ child }) =>
    child === 3 &&
    css`
      right: -10%;
      top: -25%;
    `}
`

Expertise.displayName = "Expertise"

export default Expertise
