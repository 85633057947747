import ContentComponent from "@components/pages/pagesMap"
import React from "react"
import SEO from "@components/common/SEO"
import { graphql } from "gatsby"

const Page = ({ data, pageContext }) => {
  const { title, seo, pageType, ACFPage } = data.wpPage
  const { language } = pageContext
  const currentPage = ContentComponent.filter(comp => comp.type === pageType)[0]

  if (!currentPage) return null

  const PageContent = currentPage.component

  return (
    <>
      <SEO seo={seo} language={language} />
      <PageContent title={title} content={ACFPage?.contenuti} />
    </>
  )
}

export const data = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      pageType
      ACFPage {
        contenuti {
          contentType
          copy
          gallery {
            sourceUrl
            mediaDetails {
              sizes {
                sourceUrl
                name
              }
            }
          }
        }
      }
      seo {
        twitterDescription
        title
        twitterTitle
        opengraphTitle
        opengraphDescription
        metaRobotsNofollow
        metaRobotsNoindex
        metaDesc
        twitterImage {
          sourceUrl
        }
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`

export default Page
