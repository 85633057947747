import Approach from "@components/pages/Approach"
import Contacts from "@components/pages/Contacts"
import Expertise from "@components/pages/Expertise"
import Home from "@components/pages/Home"
import Innovation from "@components/pages/Innovation"
import PrivacyPolicy from "@components/pages/PrivacyPolicy"
import Team from "@components/pages/Team"
import Vibes from "@components/pages/Vibes"

export default [
  {
    type: "home",
    component: Home,
  },
  {
    type: "approach",
    component: Approach,
  },
  {
    type: "vibes",
    component: Vibes,
  },
  {
    type: "expertise",
    component: Expertise,
  },
  {
    type: "innovation",
    component: Innovation,
  },
  {
    type: "team",
    component: Team,
  },
  {
    type: "contacts",
    component: Contacts,
  },
  {
    type: "privacy",
    component: PrivacyPolicy,
  },
]
