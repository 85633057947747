import styled, { css } from "styled-components"

import React from "react"

const Wave = ({ children, className, fullPage }) => (
  <StyledWave className={className} fullPage={fullPage}>
    {children}
  </StyledWave>
)

const StyledWave = styled.div`
  @media (max-width: 991px) {
    display: none;
  }

  ${({ fullPage }) =>
    fullPage &&
    css`
      position: absolute;
      height: 150vh;

      figure,
      img {
        height: inherit;
      }
    `}
`

Wave.displayName = "Wave"

export default Wave
